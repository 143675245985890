// Video Feature Block
.video-feature-block {
  background: url(https://res.cloudinary.com/starline-ventures/image/upload/v1465696661/bidopps/1280px-Port-of-New-Orleans-Mississippi-River.mini.jpg) 50% 50% no-repeat;
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 60px;
  .content-block-inner {
    p { color: $white; }
  }
}

// Video Feature Block Animations
.video-feature-block .content-block-inner.active {
  .feature-block-heading {
    @include animate(1s);
    animation-delay: 0.2s;
    @extend .fade-in-down-big;
  }
  p {
    @include animate(1s);
    animation-delay: 0.4s;
    @extend .fade-in;
  }
  .button-play {
    @include animate(1s);
    animation-delay: 0.6s;
    @extend .fade-in-down-big;
  }
}