// Feature Contact Block
.contact-feature-block {
  background: url(https://res.cloudinary.com/starline-ventures/image/upload/v1465696661/bidopps/1280px-Houston_Ship_Channel.mini.jpg) 50% 50% no-repeat;
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 60px;
}
.contact-feature-block.active {
  .feature-block-heading {
    @include animate(1s);
    animation-delay: 0.4s;
    @extend .fade-in-down-big;
  }
  .button {
    @include animate(1s);
    animation-delay: 0.4s;
    @extend .fade-in-up-big;
  }
}